import { Image, Modal, ModalProps, Space, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DepositReportService } from "services/deposit-report-service";
import { SlipImage } from "types/deposit-report";
import { FileImageOutlined } from "@ant-design/icons";
import styled from "styled-components";

type SlipImagesPopupProps = ModalProps & {
  images: SlipImage[] | null;
};

function SlipImagesPopup({ images, ...props }: SlipImagesPopupProps) {
  const [idsLoading, setIdsLoading] = useState<string[]>([]);
  const [imagesUrl, setImagesUrl] = useState<{ [key: SlipImage["uploadFileId"]]: string }>({});

  const getImage = useCallback(async (imageId: string) => {
    try {
      const response = await DepositReportService.getSlipImage(imageId);
      return [imageId, URL.createObjectURL(response.data)];
    } catch (error) {
      console.error("Error fetching slip image:", error);
      return null;
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (images?.length) {
        const imageIds = images.map(({ uploadFileId }) => uploadFileId);

        setIdsLoading(imageIds.filter((id) => !imagesUrl[id]));

        const promise: Promise<string[] | null>[] = [];

        for (const imageId of imageIds) {
          const imageUrl = imagesUrl[imageId];

          if (!imageUrl) {
            promise.push(getImage(imageId));
          }
        }

        const responseImages = await Promise.all(promise);
        const urls: { [key: SlipImage["uploadFileId"]]: string } = {};

        for (const responseImage of responseImages) {
          if (responseImage) {
            urls[responseImage[0]] = responseImage[1];
          }
        }

        setImagesUrl((_urls) => ({ ..._urls, ...urls }));
        setIdsLoading([]);
      }
    })();
  }, [images]);

  return (
    <Modal title={`${images?.length ?? 0} สลิป`} footer={null} maskClosable={false} {...props}>
      <Image.PreviewGroup preview={{ getContainer: () => document.body, rootClassName: "preview-slip" }}>
        <Space wrap>
          {images?.map(({ uploadFileId }) => {
            const loading = idsLoading.includes(uploadFileId);
            const imageUrl = imagesUrl[uploadFileId];

            if (loading) {
              return (
                <Spin key={uploadFileId} spinning>
                  <FileImageOutlinedStyled />
                </Spin>
              );
            }

            return <Image key={uploadFileId} width={slipImageSize} src={imageUrl} />;
          })}
        </Space>
      </Image.PreviewGroup>
    </Modal>
  );
}

export default SlipImagesPopup;

const slipImageSize = 100;

const FileImageOutlinedStyled = styled(FileImageOutlined)`
  font-size: ${slipImageSize}px;
`;
