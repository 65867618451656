import { Button, Dropdown, Input, Menu, Popover, Space } from "antd";
import React, { useState } from "react";
import { FaUserSecret } from "react-icons/fa";
import { BiLeftIndent, BiRightIndent } from "react-icons/bi";
import { BiLogOut } from "react-icons/bi";
import { batch, useDispatch, useSelector } from "react-redux";
import { clearToken, signIn } from "redux/reducers/auth";
import { AuthenticationSelector } from "redux/selectors/auth";
import styled from "styled-components";
import { useProSidebar } from "react-pro-sidebar";
import DepositSlipPusherNotification from "components/Pusher/PusherNotification";

type HeaderProps = {
  token?: string;
};

function HeaderBar({ token }: HeaderProps) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { loading, error } = useSelector(AuthenticationSelector.state);
  const { collapseSidebar, collapsed } = useProSidebar();

  const onEnter = (evt: React.KeyboardEvent<HTMLElement>) => {
    if (evt.key === "Enter" && username && password) {
      dispatch(signIn({ username, password }));
    }
  };

  const onLogout = () => {
    batch(() => {
      dispatch(clearToken());
      // dispatch(push("/"));
    });
  };

  const renderUser = () => (
    <Space size={24}>
      <DepositSlipPusherNotification />
      <Dropdown
        overlay={
          <Menu
            items={[
              {
                key: "__logout__",
                label: (
                  <div className="flex items-center text-slate-500" onClick={onLogout}>
                    <BiLogOut size={20} />
                    <span className="ml-2">ออกจากระบบ</span>
                  </div>
                ),
              },
            ]}
          />
        }
        placement="bottomLeft"
      >
        <div>
          <FaUserSecret className="cursor-pointer" title="ผู้ใช้งาน" size={20} />
        </div>
      </Dropdown>
    </Space>
  );

  const renderRequireLogin = () => (
    <Popover
      title={null}
      content={
        <div onKeyDown={onEnter}>
          <Space direction="vertical">
            <Input placeholder="รหัสผู้ใช้งาน" allowClear onChange={(evt) => setUsername(evt.target.value)} />
            <Input.Password placeholder="รหัสผ่าน" allowClear onChange={(evt) => setPassword(evt.target.value)} />
            <Button
              type="primary"
              block
              onClick={() => dispatch(signIn({ username, password }))}
              loading={loading}
              disabled={!username || !password}
            >
              เข้าสู่ระบบ
            </Button>
            {error && <div className="text-red-600">{error}</div>}
          </Space>
        </div>
      }
      trigger="click"
      placement="bottomRight"
    >
      <span className="cursor-pointer underline select-none">เข้าสู่ระบบ</span>
    </Popover>
  );
  return (
    <HeaderBarStyled className="h-full">
      <div>
        <div className="flex p-1 bg-[#2b2b2b] cursor-pointer" onClick={() => collapseSidebar(!collapsed)}>
          {collapsed ? <BiRightIndent size={27} /> : <BiLeftIndent size={27} />}
        </div>
      </div>
      {token ? renderUser() : renderRequireLogin()}
    </HeaderBarStyled>
  );
}

export default HeaderBar;

const HeaderBarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`;
