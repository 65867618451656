import { DepositReportRequest, DepositReportResponseWrapper } from "types/deposit-report";
import { AxiosAPI } from "./axios-service-creator";

export const DepositReportService = {
  fetchDepositReport: (payload: DepositReportRequest) =>
    AxiosAPI.post<DepositReportResponseWrapper>("/players/deposit", payload),
  approve: (id: string) => AxiosAPI.post("/players/deposit/approve", { depositId: id }),
  reject: (id: string) => AxiosAPI.post("/players/deposit/reject", { depositId: id }),
  getSlipImage: (id: string) => AxiosAPI.get(`/players/deposit/image/${id}`, { responseType: "blob" }),
};
