import React, { useEffect, useState } from "react";
import { List, ListRowRenderer } from "react-virtualized";
import styled, { css } from "styled-components";
import { DepositSlipNotification } from "types/deposit-report";

type DepositNotificationProps = {
  items: DepositSlipNotification[];
  onNotificationSelected: (item: DepositSlipNotification) => void;
};
type NotificationPtops = {
  item: DepositSlipNotification;
} & Pick<DepositNotificationProps, "onNotificationSelected">;

function DepositNotification({ items, onNotificationSelected }: DepositNotificationProps) {
  const [listHeight, setListHeight] = useState(MAX_HEIGHT);

  useEffect(() => {
    const dynamicHeight = Math.min(items.length * ITEM_HEIGHT, MAX_HEIGHT);
    setListHeight(dynamicHeight);
  }, [items.length]);

  const rowRenderer: ListRowRenderer = ({ key, index, style }) => {
    return (
      <NotificationItemStyled
        key={key}
        className="deposit-notification-item"
        style={style}
        $isLast={index === items.length - 1}
      >
        <Notification item={items[index]} onNotificationSelected={onNotificationSelected} />
      </NotificationItemStyled>
    );
  };

  return (
    <List
      width={250}
      height={listHeight}
      rowCount={items.length}
      rowHeight={ITEM_HEIGHT}
      rowRenderer={rowRenderer}
      style={listStyle}
      overscanRowCount={10}
    />
  );
}

export default DepositNotification;

const ITEM_HEIGHT = 40;
const MAX_HEIGHT = 300;
const listStyle = { outline: "none" };

const Notification = ({ item, onNotificationSelected }: NotificationPtops) => (
  <NotificationStyeld onClick={() => onNotificationSelected(item)}>
    {item.playerName}
    <small className="time ml-3 text-slate-400">[ {item.uploadTime?.split(" ")?.[1] ?? "-"} ]</small>
  </NotificationStyeld>
);

const NotificationStyeld = styled.div`
  display: flex;
  height: ${ITEM_HEIGHT}px;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;

  .time {
    letter-spacing: 1px;
  }
`;

interface NotificationItemStyledProps {
  $isLast: boolean;
}

const NotificationItemStyled = styled.div<NotificationItemStyledProps>`
  ${(props) =>
    !props.$isLast &&
    css`
      border-bottom: 1px solid #eeeeee;
    `}

  user-select: none;
  cursor: pointer;

  &:hover {
    background: #efefef;
  }
`;
