import React, { useCallback, useEffect, useRef, useState } from "react";
import Pusher from "pusher-js";
import { BankOutlined, SoundOutlined } from "@ant-design/icons";
import { Badge, Popover, Typography } from "antd";
import styled, { css } from "styled-components";
import DepositNotification from "./Notification/DepositNotification";
import { DepositSlipNotification } from "types/deposit-report";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { ProcessStatus } from "const";
import { useSetStatements, useStatements } from "pages/deposit-report/store";
import cloneDeep from "lodash/cloneDeep";
import { useLocation } from "react-router";
import { IProcessStatus } from "types";

function DepositSlipPusherNotification() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [panelNotificationVisible, setPanelNotificationVisible] = useState(false);
  const [notifications, setNotifications] = useState<DepositSlipNotification[]>([]);
  const timeoutRef = useRef<{ [key: string]: NodeJS.Timeout }>({});
  const [useSoundNotification, setUseSoundNotification] = useState(
    localStorage.getItem("useSoundNotification") === "1"
  );

  const depositStatements = useStatements();
  const setDepositStatements = useSetStatements();
  const addDepositSlipImageFuncRef = useRef<(id: string, imageRef: string, status: IProcessStatus) => void>();
  const completedDepositFuncRef = useRef<(id: string, status: IProcessStatus, approveBy: string) => void>();

  useEffect(() => {
    addDepositSlipImageFuncRef.current = (id: string, uploadFileId: string, status: IProcessStatus) => {
      if (location.pathname === "/report/deposit") {
        const newList = cloneDeep(depositStatements);

        const depositTransIndex = depositStatements.findIndex((d) => d.id === id);

        if (~depositTransIndex) {
          if (!newList[depositTransIndex].images) {
            newList[depositTransIndex].images = [];
          }

          newList[depositTransIndex].status = status;
          newList[depositTransIndex].images?.push({ uploadFileId });

          setDepositStatements(newList);
        }
      }
    };

    completedDepositFuncRef.current = (id: string, status: IProcessStatus, approveBy: string) => {
      if (location.pathname === "/report/deposit") {
        const newList = cloneDeep(depositStatements);

        const depositTransIndex = depositStatements.findIndex((d) => d.id === id);

        if (~depositTransIndex) {
          newList[depositTransIndex].status = status;
          newList[depositTransIndex].approveBy = approveBy;
          setDepositStatements(newList);
        }
      }
    };
  }, [depositStatements]);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY as string, {
      cluster: "ap1",
    });

    const channel = pusher.subscribe("auto-channel");

    channel.bind("upload-slip-event", function (json: string) {
      const data: DepositSlipNotification = JSON.parse(json);

      if (localStorage.getItem("useSoundNotification") === "1") {
        beep(400, 440, 15);
      }

      setNotifications((notifications) => [...notifications, data]);

      if (addDepositSlipImageFuncRef.current) {
        addDepositSlipImageFuncRef.current(data.depositRequestId, data.uploadFileId, data.status as IProcessStatus);
      }

      const ref = data.uploadFileId;
      timeoutRef.current[ref] = setTimeout(() => {
        delete timeoutRef.current[ref];
        setNotifications((notifications) => notifications.filter((notification) => notification.uploadFileId !== ref));
      }, 15 * 60 * 1000);
    });

    channel.bind("deposit-status-event", function (json: string) {
      const data: DepositSlipNotification = JSON.parse(json);

      if (completedDepositFuncRef.current) {
        completedDepositFuncRef.current(data.depositRequestId, data.status as IProcessStatus, data.approveBy as string);
      }

      setNotifications((notifications) =>
        notifications.filter((notification) => {
          const notMatch = notification.depositRequestId !== data.depositRequestId;

          if (!notMatch) {
            clearTimeout(timeoutRef.current[notification.uploadFileId]);
            delete timeoutRef.current[notification.uploadFileId];
          }

          return notMatch;
        })
      );
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  const onNotificationSelected = useCallback(
    (item: DepositSlipNotification) => {
      setNotifications((notifications) =>
        notifications.filter((notification) => {
          const notMatch = notification.playerName !== item.playerName;

          if (!notMatch) {
            clearTimeout(timeoutRef.current[notification.uploadFileId]);
            delete timeoutRef.current[notification.uploadFileId];
          }

          return notMatch;
        })
      );

      dispatch(push("/report/deposit", { playerName: item.playerName, status: ProcessStatus.Pending }));
    },
    [dispatch]
  );

  const onToggleSoundNotification = () => {
    setUseSoundNotification(!useSoundNotification);
    localStorage.setItem("useSoundNotification", !useSoundNotification ? "1" : "0");
  };

  return (
    <div className="deposit-notification">
      <Popover
        open={panelNotificationVisible}
        onOpenChange={setPanelNotificationVisible}
        content={<DepositNotification items={notifications} onNotificationSelected={onNotificationSelected} />}
        title={
          <DepositNotificationTitleStyled>
            <Typography.Text strong style={{ lineHeight: 2 }}>
              รายการฝาก
            </Typography.Text>
            <div>
              <SoundOutlinedStyled
                className="mr-2"
                $useSoundNotification={useSoundNotification}
                onClick={onToggleSoundNotification}
              />
            </div>
          </DepositNotificationTitleStyled>
        }
        trigger="click"
        placement="rightTop"
      >
        <Badge count={notifications.length} overflowCount={999}>
          <BankOutlinedStyled />
        </Badge>
      </Popover>
    </div>
  );
}

export default DepositSlipPusherNotification;

const BankOutlinedStyled = styled(BankOutlined)`
  color: white;
  font-size: 24px;
  transform: translateY(-1px);
`;

interface SoundOutlinedProps {
  $useSoundNotification: boolean;
}

const SoundOutlinedStyled = styled(SoundOutlined)<SoundOutlinedProps>`
  font-size: 18px;
  cursor: pointer;

  ${(props) =>
    !props.$useSoundNotification &&
    css`
      color: #d1d1d1;
    `}
`;

const DepositNotificationTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const mockNotifications: DepositSlipNotification[] = Array.from({ length: 2 }, (_, index) => ({
//   depositRequestId: "65c888d08f84cc7e47f31497",
//   playerName: index + " - pgc0821613050",
//   uploadFileId: index + " - ab65ca42-a611-4a59-a971-ff4ee4763e11",
//   uploadOriginalFileName: "423472197_753787479683585_3349651604913957145_n.jpg",
//   uploadTime: "11-02-2024 15:44:07",
// }));

function beep(duration: number, frequency: number, volume: number) {
  const audioCtx = new window.AudioContext();
  const oscillator = audioCtx.createOscillator();
  const gainNode = audioCtx.createGain();

  oscillator.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  gainNode.gain.value = volume * 0.01;
  oscillator.frequency.value = frequency;
  oscillator.type = "triangle";

  oscillator.start(audioCtx.currentTime);
  oscillator.stop(audioCtx.currentTime + duration * 0.001);
}
