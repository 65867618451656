import { message } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import LabelUnit from "components/Text/LabelUnit";
import PageContainer from "pages/container/PageContainer";
import React, { memo, useCallback, useEffect, useRef } from "react";
import { DepositReportService } from "services/deposit-report-service";
import { DepositReport } from "types/deposit-report";
import { alertError } from "utils/error-util";
import DepositReportTable from "./DepositReportTable";
import FilterSection from "./FilterSection";
import {
  useFilters,
  useForceUpdate,
  useLoading,
  usePagination,
  useSetFilters,
  useSetForceUpdate,
  useSetLoading,
  useSetPagination,
  useSetStatements,
  useSetTotal,
  useStatements,
  useTotal,
} from "./store";
import { useLocation } from "react-router";

function DepositReportPage() {
  const location = useLocation<Pick<DepositReport, "playerName" | "status">>();
  const loading = useLoading();
  const statements = useStatements();
  const pagination = usePagination();
  const total = useTotal();
  const forceUpdate = useForceUpdate();
  const setLoading = useSetLoading();
  const setTotal = useSetTotal();
  const setPagination = useSetPagination();
  const setStatements = useSetStatements();
  const filters = useFilters();
  const setFilters = useSetFilters();
  const setForceUpdate = useSetForceUpdate();

  const renderCount = useRef(0);
  const skipFirstSearch = useRef(false);

  renderCount.current++;
  skipFirstSearch.current = renderCount.current === 1 && !!location.state;

  useEffect(() => {
    const { playerName, status } = location.state || {};

    if (playerName) {
      setFilters({ playerName, status });
      setForceUpdate();
    }
  }, [setFilters, location]);

  useEffect(() => {
    (async () => {
      if (!skipFirstSearch.current) {
        try {
          setLoading(true);
          const response = await DepositReportService.fetchDepositReport({
            playerName: filters.playerName ? filters.playerName : undefined,
            status: filters.status,
            page: pagination.current ?? 0,
            perPage: pagination.pageSize ?? 0,
          });
          const { currentPage, perPage, total, depositList } = response.data.result;
          setStatements(depositList);
          setPagination({ current: currentPage, pageSize: perPage });
          setTotal(total);
        } catch (error) {
          alertError(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [forceUpdate, filters, pagination]);

  const onSearch = useCallback(() => {
    setPagination({ current: 1 });
    setForceUpdate();
  }, []);

  const refresh = useCallback(() => {
    setForceUpdate();
  }, []);

  const onTableChange = useCallback((pagination: TablePaginationConfig) => {
    setPagination(pagination);
  }, []);

  const onApprove = useCallback(
    async (data: DepositReport) => {
      try {
        setLoading(true);
        await DepositReportService.approve(data.id);
        message.success("ยืนยันรายการสำเร็จ");
        refresh();
      } catch (error) {
        alertError(error);
      } finally {
        setLoading(false);
      }
    },
    [refresh]
  );

  const onReject = useCallback(
    async (data: DepositReport) => {
      try {
        setLoading(true);
        await DepositReportService.reject(data.id);
        message.success("ยกเลิกรายการสำเร็จ");
        refresh();
      } catch (error) {
        alertError(error);
      } finally {
        setLoading(false);
      }
    },
    [refresh]
  );

  return (
    <PageContainer className="deposit-report-page">
      <FilterSection onSearch={onSearch} />
      <div className="mt-4 c-container">
        <LabelUnit
          className="text-lg lg:absolute top-9 left-6"
          label="ประวัติรายการฝาก"
          number={total}
          suffix="รายการ"
          renderNumber={(number) => (
            <strong>
              <code>{number}</code>
            </strong>
          )}
          format
        />
        <DepositReportTable
          loading={loading}
          dataSource={statements}
          pagination={pagination}
          total={total}
          onChange={onTableChange}
          onApprove={onApprove}
          onReject={onReject}
        />
      </div>
    </PageContainer>
  );
}

export default memo(DepositReportPage);
